<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 3</h1>
      <h3>Assemble Transcriptome with Stringtie and Scallop</h3>
      <p class="text-left">Two assemblers Stringtie and Scallop are used to assemble transcripts in each sample from the STAR read mapping results.</p>
      <p class="text-left">Parameter settings:
        <ul>
          <li>Library Type: Sequencing library type of the samples. Options: fr-firststrand, fr-secondstrand and fr-unstranded.</li>
        </ul>
      </p>

      <p class="m-0 font-weight-bold">Select the folder which contains the relevant mapping results:</p>
      <b-row><b-col>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
    
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected">
        </folder-browser>
      <div class="text-left">
        <p>Notes:</p>
        <p>You can ask the data producer (e.g. the one who prepared the RNA-seq library) or check your RNA sequencing report to find out the protocol used to generate the data. More details can be found <a :href="`http://onetipperday.sterding.com/2012/07/how-to-tell-which-library-type-to-use.html`">here</a></p>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Library Type</th>
              <th scope="col">Examples</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">fr-firststrand</th>
              <td>dUTP, NSR, NNSR</td>
              <td>Same as above except we enforce the rule that the right-most end of the fragment (in transcript coordinates) is the first sequenced (or only sequenced for single-end reads). Equivalently, it is assumed that only the strand generated during first strand synthesis is sequenced.</td>
            </tr>

            <tr>
              <th scope="row">fr-secondstrand</th>
              <td>Ligation, Standard SOLiD</td>
              <td>Same as above except we enforce the rule that the left-most end of the fragment (in transcript coordinates) is the first sequenced (or only sequenced for single-end reads). Equivalently, it is assumed that only the strand generated during second strand synthesis is sequenced.</td>
            </tr>
            <tr>
              <th scope="row">fr-unstranded</th>
              <td>Unstrand Illumina</td>
              <td>Reads from the left-most end of the fragment (in transcript coordinates) map to the transcript strand, and the right-most end maps to the opposite strand.</td>
            </tr>
          </tbody>
        </table>

      </div>

        <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Library Type:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="libraryTypeTip">
                <b-form-select id="libraryTypeInput" v-model="libraryType" :options="options" class="bg-secondary"></b-form-select>
              </div>
            </b-input-group>
          </b-row>

          <b-form-checkbox
            id="NameCheck"
            v-model="nameCheck"
            name="NameCheck"
          >
            Tick if you want to use a custom name for the results folder.
          </b-form-checkbox>

          <b-row v-if="nameCheck" class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box">
                <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

        <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
      </b-form>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: "assembly",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      libraryType: "fr-firststrand",
      options: [
        "fr-firststrand",
        "fr-secondstrand",
        "fr-unstranded"
      ],
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=sr3",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        this.folderID = this.$route.params.resubmitData.mappingFolder;
        this.libraryType = this.$route.params.resubmitData.libType;
        if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
        {
          if(this.folderID !== undefined && this.folderID !== null)
          {
            this.folders.forEach(folder => {
              if((folder.id.localeCompare(this.folderID) == 0))
              {
                this.folderSelected(folder);
              }
            });
          }
        }
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/shortread/assembly1", {
        params: {
          token: this.token,
          mappingFolderID: this.selectedFolder.id,
          libType: this.libraryType,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.assemblyFolderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
</style>